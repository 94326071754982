.player-wrapper {
  position: relative;
  width:100%;
  aspect-ratio: 560/314;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  background: #4d4d50;
}
  
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}