.footer{
    background-color: #1f202c;
    color: rgba(240,238,234,.5) !important;
    width:100%;
    padding: 1em 0 3em 0 ;
    text-align: center;
}
.small-text{
    font-size: 90%;
    font-weight: 400;
}